<script>
import DialogApplication from "@/components/Dialog/DialogApplication.vue"

export default {
	name: "CardNoVacations",
	components: {DialogApplication},
	props: {
		currentYear: {
			type: Number,
			required: true,
		},
		holidays: {
			type: Array,
			default: () => [],
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		daysAvailable: {
			type: Number,
			required: true,
		},
		additionalDaysAvailable: {
			type: Number,
			required: true,
		},
	},
	data: () => ({
		dialog: false,
	}),
}
</script>

<template>
	<v-card
		outlined
	>
		<v-card-title>
			<h3>Нет отпусков в текущем году</h3>
		</v-card-title>
		<v-card-text>
			<p>Для начала необходимо добавить отпуск в {{ currentYear }} году</p>
		</v-card-text>
		<v-card-actions>
			<v-btn
				color="primary"
				class="mx-auto px-6"
				depressed
				:disabled="disabled"
				rounded
				@click="dialog = true"
			>
				Добавить отпуск
			</v-btn>
		</v-card-actions>
		<DialogApplication
			v-model="dialog"
			:current-year="currentYear"
			:holidays="holidays"
			:days-available="daysAvailable"
			:additional-days-available="additionalDaysAvailable"
			mode="create"
			@update="$emit('update')"
		/>
	</v-card>
</template>